import { useCallback, memo } from "react";
import PropTypes from "prop-types";
import "./LogoBrand.scss";
import { brandPropTypes } from "app/utils/propTypes";
import { sendTagOnLogoRedirect } from "app/utils/analytics";
import classNames from "classnames";

const LogoBrand = ({
	shop = "",
	brand = "",
	isWhite = false,
	theme = {},
	showSpanLogo = false,
	partnerCode,
}) => {
	const onClick = useCallback(() => {
		sendTagOnLogoRedirect();
	}, []);

	const logoBrandClassNames = classNames({
		[`logo-brand logo-brand--${brand}-${shop}`]: true,
		[`logo-brand logo-brand--${brand}-${shop}-white`]: isWhite,
		[`logo-brand logo-brand--AFH-${shop}`]: partnerCode === "AFHOFRFR", // TODO code à supprimer quand AFFR meurt
		[`logo-brand logo-brand--AFH-${shop}-white`]: isWhite && partnerCode === "AFHOFRFR", // TODO code à supprimer quand AFFR meurt
	});
	return theme?.logo && !showSpanLogo ? (
		<img
			alt="logo-brand"
			data-testid="logo-brand"
			onClick={onClick}
			src={theme?.logo}
			className={"logo-brand__logo"}
		/>
	) : (
		<span data-testid="logo-brand" onClick={onClick} className={logoBrandClassNames} />
	);
};

LogoBrand.propTypes = {
	shop: PropTypes.string,
	isWhite: PropTypes.bool,
	brand: brandPropTypes,
	theme: PropTypes.object,
	showSpanLogo: PropTypes.bool,
	partnerCode: PropTypes.string,
};

export default memo(LogoBrand);
